import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import { hasPermission, Permission } from '@asaprint/common/access.js';
import React from 'react';

interface OwnProps {
  permission: Permission | Permission[];
  all?: boolean;
  children: React.ReactNode;
  noPermission?: React.ReactNode;
}

type Props = OwnProps;

const HasPermission: React.FunctionComponent<Props> = ({ permission, all, children, noPermission }) => {
  const { requestUser } = useAuth();
  if (hasPermission(requestUser.permissions as Permission[], permission, all)) {
    return <>{children}</>;
  }

  return <>{noPermission}</>;
};

HasPermission.displayName = 'HasPermission';

export default HasPermission;
